import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Skill Practice:`}</em>{` Parallette L Holds`}</p>
    <p>{`then, 10:00 EMOM of:`}</p>
    <p>{`:20 Parallette L Hold -minutes 1,3,5,7,9`}</p>
    <p>{`20 Situps -minutes 2,4,6,8,10`}</p>
    <p>{`then at minute 13:00,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`1 Lap Run`}</p>
    <p>{`30 Wall Balls (20/14`}{`#`}{`)`}</p>
    <p><strong parentName="p">{`*`}{`Bonus WOD`}{`*`}</strong></p>
    <p>{`Tabata (8 rounds, :20 on, :10 off) Assault Bike for total calories.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      